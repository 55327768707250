import React, { useState } from 'react'
import styled from 'styled-components'
import { Wrapper } from '../wrapper'
import { Container } from '../container'
import { Heading } from '../heading'
import { Button } from '../button'
import { device  } from '../../../utils/breakpoints'
import Layout from '../../layout/index'
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from '../../seo'

//material-ui

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';


import { MdKeyboardArrowDown } from 'react-icons/md'

//Slider

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/bundle'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])




const StyledWrapper = styled.div `
     padding: 5rem 5vw;
    width: 100%;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
 
    
`

const StyledContainer = styled.div `
    display: flex;
    flex-direction: column;
    max-width: 1366px;
    width: 100%;
    gap: 4rem;


    span {
        color: ${({ theme }) => theme.colors.green};
    }
`

// Slider

const StyledSwiper = styled(Swiper) `
    width: 100%;

    display: grid;
    justify-items: center;
    align-content: center;


    .swiper-button-prev:after,
    .swiper-button-next:after {
      color: #1B8640;
      
    }

    .swiper-button-prev,
    .swiper-button-next {
        display: none;
    }

    .swiper-pagination-bullet {
        background: #1B8640;
    }

    .swiper-wrapper {
       


    }

    .swiper-slide {
        width: 100% !important;
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
        transition-property: transform;
    }

`

const ContainerImage = styled.div `
    max-width: 700px;
    .image-styles-hero {

    }
`

const Img = styled(GatsbyImage) `



`



// Cennik

const AccordionDetail = styled(AccordionDetails) `
    display: flex;
    flex-direction: column;

  div {
      display: block;
      overflow-x: auto;
      table {
        width: 100%;
        border: 1px solid black;
        
        th {
          background: ${({ theme }) => theme.colors.green};
          color: #fff;
          min-width: 203px;
          box-sizing: border-box;
          padding: 1rem;
          border: 1px solid black;
        }

        tr:nth-child(even) {
          background-color: #F0F0F0;
        }

        tr {
            background-color: #fff;
          
          td {
            min-width: 250px;
            line-height: 120%;
            box-sizing: border-box;
            padding: 0.75rem;
            border: 1px solid black;
          } 

        }
      }
    }
`


//hero
const HeroTreatment = styled.div `
    display: flex;
    flex-direction: column;
    gap: 3rem;
  
    align-content: start;

    @media ${device.tabletL} { 
        flex-direction: row;
    }
`


const ContainerHeroInforamtion = styled.div `
    display: grid;
    align-content: start;
    gap: 2rem;
    max-width: 550px;


`


const StyledHeadingHero = styled(Heading) `
    font-weight: 600;
    color: ${({ theme }) => theme.colors.green};
`


const ContainerButtons = styled.div `
    display: grid;
    grid-template-columns: auto;
    justify-content: start;
    gap: 1rem;

    @media ${device.mobileXL} {
        grid-template-columns: repeat(2, auto);
    }
`


const ContainerSlider = styled.div `
    display: grid;
    justify-content: end;    
`


//more info
const ContainerMoreInformationsTreatment = styled.div `
    display: flex;
    flex-direction: column-reverse;
    gap: 2rem;
    position: relative;
    background: ${({ theme }) => theme.colors.white};
    width: 100%;
    padding: 0.5rem;
    border-radius: 5px;

    @media ${device.mobileXL} { 
        padding: 2rem;
    }

    @media ${device.tabletL} { 
        flex-direction: row;
    }
`

//left
const ContainerLongDescriptionTreatments = styled.div `
    display: grid;
    align-content: start;
    grid-template-rows: auto;
    gap: 2rem;

    p {
        font-size: ${({ theme }) => theme.fonts.size.xs};
    }

    li {
        list-style-type: circle;
        font-size: ${({ theme }) => theme.fonts.size.xs};
    }

    ul {
        line-height: 1.5;
    }

    @media ${device.tabletL} { 
        width: 50%;
    }

    @media ${device.mobileXL} { 
        p {
            font-size: ${({ theme }) => theme.fonts.size.s};
        }

        li {
            font-size: ${({ theme }) => theme.fonts.size.s};
        }
    }



`

const ContainerLongDescriptionTreatment = styled.div `
    display: grid;
    gap: 1rem;
`

const StyledHeadingMoreInfo = styled(Heading)`
    color: ${({ theme }) => theme.colors.green};
    font-weight: 500;
`


//right
const ContainerBasicDescriptionTreatment = styled.div `
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-content: start;

    @media ${device.tabletL} { 
        width: 50%;
    }

`

const BasicDescriptionsTreatment = styled.div `
    display: grid;
    grid-template-rows: auto;
    padding: 2rem 1rem;
    -webkit-box-shadow: 0px 3px 6px 3px rgba(0,0,0,0.03); 
    box-shadow: 0px 3px 6px 3px rgba(0,0,0,0.03);
    border-radius: 5px;
`

const DescriptionTreatment = styled.div `
    display: grid;
    gap: 2rem;
    align-content: start;
    
`

const StyledHeadingDescriptionTreatment = styled(Heading) `
    font-weight: 500;
    color: ${({ theme }) => theme.colors.green};
    font-size: 1.1rem;
`

const ContainerBasicInformations = styled.div `

`

const BasicInformation = styled.div `
    display: grid;
    padding: 1rem 0;
    grid-template-columns: repeat(2, auto);
    justify-content: space-between; 
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(112, 112, 112, 0.13);

    span {
        font-size: ${({ theme }) => theme.fonts.size.xs};
        line-height: 1.2;
    }

    @media ${device.tablet} { 
        span {
            font-size: ${({ theme }) => theme.fonts.size.s};
        }
    }

`

//price


const ContainerPricingTreatment = styled.div `
    margin-bottom: 2rem;
`

const StyledAccordion = styled(Accordion) `
    -webkit-box-shadow: 0px 3px 6px 3px rgba(0,0,0,0.03) !important; 
    box-shadow: 0px 3px 6px 3px rgba(0,0,0,0.03) !important; 
    border-radius: 5px !important; 

    border-radius: 5px !important; 
    border-top: 0px;
    border: 1px solid white !important; 

`


const BydgoszczPageLaseroterapia = ({ data }) => {



    const [expanded, setExpanded] = useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false)
    };

    const lodzKosmetologiaEstetyczna = data.contentfulBydgoszczLaseroterapia
  

    return (
        <Layout bydgoszcz>
            <Seo
                title={lodzKosmetologiaEstetyczna.titleSeo}
                description={lodzKosmetologiaEstetyczna.descriptionSeo}
                url={`https://bodymedgroup.pl/bydgoszcz-zabiegi/bydgoszcz-kosmetologia-estetyczna/${lodzKosmetologiaEstetyczna.slug}`}
            />
            <StyledWrapper>
                <StyledContainer>
                    <HeroTreatment>

                        <ContainerHeroInforamtion>
                            <StyledHeadingHero h1 as='h1'>{lodzKosmetologiaEstetyczna.nazwaZabiegu}</StyledHeadingHero>
                            <p>{lodzKosmetologiaEstetyczna.opisZabiegu.opisZabiegu}</p>
                            <ContainerButtons>
                                <Button to='https://www.moment.pl/bodymedgroup-bydgoszcz'>Zarezerwuj wizytę</Button>
                                <Button isGreen to='/bydgoszcz-kontakt'>Zapytaj eksperta</Button>
                            </ContainerButtons>
                        </ContainerHeroInforamtion>

                        <ContainerSlider>
                            <StyledSwiper
                                navigation   
                                pagination={{ clickable: true }}
                            >
                                {lodzKosmetologiaEstetyczna.zdjeciaSlider.map((image) => {
                                    return (
                                        <SwiperSlide>
                                            <ContainerImage>
                                                <Img imgClassName="image-styles-hero" image={image.gatsbyImageData} />
                                            </ContainerImage>
                                        </SwiperSlide>
                                    )
                                })

                                }
                            </StyledSwiper>
                        </ContainerSlider>

                    </HeroTreatment>


                    <ContainerMoreInformationsTreatment>

                        <ContainerLongDescriptionTreatments>
                            <ContainerLongDescriptionTreatment>
                                <StyledHeadingMoreInfo h4 as='h4'>Opis Zabiegu</StyledHeadingMoreInfo>
                                <div dangerouslySetInnerHTML={ {__html: lodzKosmetologiaEstetyczna.dokladnyOpisZabiegu.childMarkdownRemark.html}}></div>
                                    
                            </ContainerLongDescriptionTreatment>

                            <ContainerLongDescriptionTreatment>
                                <StyledHeadingMoreInfo h4 as='h4'>Rezultaty</StyledHeadingMoreInfo>
                                <div dangerouslySetInnerHTML={ {__html: lodzKosmetologiaEstetyczna.rezultaty.childMarkdownRemark.html}}></div>
                            </ContainerLongDescriptionTreatment>

                            <ContainerLongDescriptionTreatment>
                                <StyledHeadingMoreInfo h4 as='h4'>Czas trwania i rekomendowana ilość żabiegów</StyledHeadingMoreInfo>
                                <div dangerouslySetInnerHTML={ {__html: lodzKosmetologiaEstetyczna.czasTrwaniaIRekomendowanaIlosc.childMarkdownRemark.html}}></div>
                            </ContainerLongDescriptionTreatment>

                            <ContainerLongDescriptionTreatment>
                                <StyledHeadingMoreInfo h4 as='h4'>Przeciwskazania</StyledHeadingMoreInfo>
                                <div dangerouslySetInnerHTML={ {__html: lodzKosmetologiaEstetyczna.przeciwskazania.childMarkdownRemark.html}}></div>
                            </ContainerLongDescriptionTreatment>

                            <ContainerLongDescriptionTreatment>
                                <StyledHeadingMoreInfo h4 as='h4'>Zalecenia po zabiegu</StyledHeadingMoreInfo>
                                <div dangerouslySetInnerHTML={ {__html: lodzKosmetologiaEstetyczna.zaleceniaPoZabiegu.childMarkdownRemark.html}}></div>
                            </ContainerLongDescriptionTreatment>
                        </ContainerLongDescriptionTreatments>



                        <ContainerBasicDescriptionTreatment>
                            <BasicDescriptionsTreatment>
                                <DescriptionTreatment>
                                    <StyledHeadingDescriptionTreatment h4 as='h4'>Podstawowe informacje</StyledHeadingDescriptionTreatment>
                                    <ContainerBasicInformations>
                                        <BasicInformation>
                                            <span>Czas trwania zabiegu:</span>
                                            <span>{lodzKosmetologiaEstetyczna.czasTrwaniaZabiegu} minut</span>
                                        </BasicInformation>

                                        <BasicInformation>
                                            <span>Rekomendowana ilość zabiegów:</span>
                                            <span>{lodzKosmetologiaEstetyczna.rekomendowanaIloscZabiegu}</span>
                                        </BasicInformation>

                                        <BasicInformation>
                                            <span>Cel:</span>
                                            <span>{lodzKosmetologiaEstetyczna.cel}</span>
                                        </BasicInformation>
                                    
                                    </ContainerBasicInformations>
                                </DescriptionTreatment>
                            </BasicDescriptionsTreatment>


                            <ContainerPricingTreatment>
                                <StyledAccordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<MdKeyboardArrowDown size="25" color="#1B8640" />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <StyledHeadingDescriptionTreatment h4 as='h4'>
                                            Cennik
                                        </StyledHeadingDescriptionTreatment>
                                    </AccordionSummary>
                                    
                                    <AccordionDetail>
                                        <div dangerouslySetInnerHTML={ {__html: lodzKosmetologiaEstetyczna.cennik.childMarkdownRemark.html}}></div>
                                    </AccordionDetail>


                                </StyledAccordion>
                            </ContainerPricingTreatment>
                        </ContainerBasicDescriptionTreatment>
                    </ContainerMoreInformationsTreatment>
                </StyledContainer>
            </StyledWrapper>
        </Layout>
    )
}

export default BydgoszczPageLaseroterapia

export const query = graphql`
  query ($slug: String) {
    contentfulBydgoszczLaseroterapia( slug: { eq: $slug }) {
        nazwaZabiegu
        opisZabiegu {
          opisZabiegu
        }
        zdjeciaSlider {
          gatsbyImageData(layout: CONSTRAINED)
        }
        dokladnyOpisZabiegu {
          childMarkdownRemark {
            html
          }
        }
        rezultaty {
          childMarkdownRemark {
            html
          }
        }
        czasTrwaniaIRekomendowanaIlosc {
          childMarkdownRemark {
            html
          }
        }
        przeciwskazania {
          childMarkdownRemark {
            html
          }
        }
        zaleceniaPoZabiegu {
          childMarkdownRemark {
            html
          }
        }
        czasTrwaniaZabiegu
        rekomendowanaIloscZabiegu
        cel
        cennik {
          childMarkdownRemark {
            html
          }
        }
        titleSeo
        descriptionSeo
    }

    
  }
`
